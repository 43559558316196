<template>
    <ui-data-table 
        :p-headers="headers"
        p-title="Cidades"
        p-read-link="cities.read.one"
        p-create-link="cities.create"
        p-name="cities"
    />
</template>
<script>

import {uiDataTable} from '@/components/ui'
export default {
    name: 'vwCityRead',
    components: {
        uiDataTable  
    },
    data(){
        return {
            headers: [{
                text: 'id',
                value: 'id'
            }, {
                text: 'descrição',
                value: 'descricao'
            }, {
                text: 'Estado',
                value: 'uf'
            }, {
                text: 'Código IBGE',
                value: 'codigo_ibge'
            }, {
                text: 'Cadastrado',
                value: 'criado_em'
            }, {
                text: 'Ações', 
                value: 'actions', 
                sortable: false
            }],
        }
    },
}
</script>